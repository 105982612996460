import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { navigate } from "gatsby"

const initialOptions = {
  "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "capture",
  commit: true
};



const DonatePage = () => {
  const [amount, setAmount] = useState(25);
  const [orderID, setOrderID] = useState(false);

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: amount,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING"
        }
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  }

  function onChange({ target: { value } }) {
    setAmount(value);
    setOrderID(false);
  }

  function approveOrder(data, actions) {
    return navigate("/donate-thanks");
  }

  return (
    <>
      <Layout>
        <SEO title="Поддержите Развите ЗАО Театра / Donate to ZAO Theater" />
        <section>
          <h1>Поддержите Развитие ЗАО Театра</h1>
          <p>Студия театральной импровизации ZaO – независимый профессиональный театр, который с 2007 года разрабатывает метафизику драмы и новый карнавал.
            То, что ZaO-Театр существует в Кишиневе с 2007 года без постоянной поддержки, благодаря энтузиазму участников и зрителей, похоже на чудо. Сейчас мы стремимся расширить границы этого чуда: гармонизировать материальную сторону процесса.
            <br/>
            <strong>Мы ищем средства на аренду помещения, костюмы, реквизит; на создание медиапродуктов хорошего качества.</strong>
          </p>
          <hr/>
          <h2>Donate For The Development of ZAO Theater</h2>
          <p>
            Impro Theater Studio ZaO is an independent professional theater that has been developing metaphysics of drama and a new carnival since 2007. We didn’t receive any permanent sponsorship, so by now we've managed to do it on our own and with the help of our audience, but now we need more support to expand the process.
            <br/>
            <strong>We are looking for funds to rent premises, costumes, props, to create good quality media projects.</strong>
          </p>
          <div className='row gtr-uniform'>
            <form className='col-5 col-6-medium col-12-small'>
              <div>
                <input type="radio" name="amount" value="15" onChange={onChange} id="donate_coffee" /><label htmlFor="donate_coffee">€15.00 - Fund 1 scene prop / финансирую реквизит</label>
              </div>
              <div>
                <input type="radio" name="amount" value="25" onChange={onChange} id="donate_coffee_xl" /><label htmlFor="donate_coffee_xl">€25.00 - Fund 1 costume / финансирую костюм</label>
              </div>
              <div>
                <input type="radio" name="amount" value="50" onChange={onChange}  id="donate_support" defaultChecked /><label htmlFor="donate_support">€50.00 - Fund filming expenses / финансирую съемки</label>
              </div>
              <div>
                <input type="radio" name="amount" value="200" onChange={onChange}  id="donate_support_xl" /><label htmlFor="donate_support_xl">€200.00 - Fund studio rent / финансирую аренду студии</label>
              </div>
              <p>Generated Order ID: {orderID ? orderID : "Select an Option first..."}</p>
            </form>
            <div className="col-7 col-6-medium col-12-small">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons createOrder={createOrder} forceReRender={[amount]} onApprove={approveOrder}/>
              </PayPalScriptProvider>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default DonatePage
